import "./styles/Home.css";
import Main from "./components/main";

export default function Home() {
  return (
    <>
      <Main />
    </>
  );
}
